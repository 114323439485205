import { render, staticRenderFns } from "./card.vue?vue&type=template&id=3587668e&scoped=true&"
import script from "./card.vue?vue&type=script&lang=js&"
export * from "./card.vue?vue&type=script&lang=js&"
import style0 from "./card.vue?vue&type=style&index=0&id=3587668e&scoped=true&lang=css&"
import style1 from "./card.vue?vue&type=style&index=1&id=3587668e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3587668e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VContainer,VDivider,VProgressLinear})
