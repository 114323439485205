<template>
  <div class="content-accounts-receivable-card-all">
    <div v-if="bLoadingTable" class="content-progress-linear">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
      ></v-progress-linear>
    </div>
    <div
      class="content-placeholder-multitable-global"
      v-if="bEmptyTable"
    >
      <span class="text-placeholder-multitable-global"
        >No hay información disponible para mostrar.</span
      >
    </div>
    <div
      v-for="(item, i) in aCards"
      :key="i"
      class="content-accounts-receivable-table"
    >
      <div class="content-table-mobile" >
        <div>
          <v-container>
            <div class="content-all-first">
              <div class="content-left-first">
                <span>
                  {{ item.sName }}
                </span>
              </div>
              <div class="content-right-first">
                <!-- <menu-accion-table-component-global @openDetailItem="openDetailItem" @openDeleteItem="openDeleteItem"
                :aMenuAccion="aMenuAccion" :item="item" /> -->
              </div>
            </div>
            <!-- <div class="content-all">
              <div class="content-left">
                <p>#</p>
              </div>
              <div class="content-right">
                <p class="text-right">{{ item.sNumber }}</p>
              </div>
            </div> -->
            <!-- <div class="content-all">
              <div class="content-left">
                <p>Cliente</p>
              </div>
              <div class="content-right">
                <p class="text-right">{{ item.sClient }}</p>
              </div>
            </div> -->
            <div class="content-all">
              <div class="content-left">
                <p>Rango de tiempo</p>
              </div>
              <div class="content-right">
                <p class="text-right">{{ item.sDateRange }}</p>
              </div>
            </div>
            <div class="content-all">
              <div class="content-left">
                <p>Variación</p>
              </div>
              <div class="content-right">
                <p class="text-right">{{ item.dVariation }}</p>
              </div>
            </div>
            <div class="content-all">
              <div class="content-left">
                <p>Adeudo total</p>
              </div>
              <div class="content-right">
                <p class="text-right">
                  {{ item.dTotalDebit }}
                </p>
              </div>
            </div>
            <!-- <div class="content-all">
              <div class="content-left">
                <p>Cantidad por cobrar</p>
              </div>
              <div class="content-right">
                <p class="text-right">
                  {{ item.dCollectQuantity }}
                </p>
              </div>
            </div> -->
          </v-container>
          <v-divider class="divider-global"></v-divider>
        </div>

        <!-- <footer-table-component-global :numPages="numPages" :totalItems="totalItems" @changeTable="changeTable"
        :bChangeSelectedItem="bChangeSelectedItem" /> -->
      </div>

      <!-- <div class="content-total-amount-pay-all">
        <div class="content-txt-price-total-amount-pay-all">
          <div class="content-txt-total-amount-pay">
            <p class="txt-total-amount-pay">Cantidad total a cobrar</p>
          </div>
          <div class="content-price-total-amount-pay">
            <p class="price-total-amount-pay">{{ item.dTotalToCollect }} MXN</p>
          </div>
        </div>
      </div> -->
    </div>

    <footer-table-component-global
    v-if="!bEmptyTable"
      class="footer-card-all-accounts-receivable"
      :numPages="numPages"
      :totalItems="totalItems"
      @changeTable="getEmitAccountsReceivable"
    />
  </div>
</template>

<script>
export default {
  props: {
    aCards: Array,
    aHeaders: Array,
    numPages: Number,
    totalItems: Number,
  },
  data() {
    return {
      bLoadingTable: true,
      bEmptyTable: false,
    };
  },
  beforeMount() {
    this.setLoadingTable();
  },
  methods: {
    setLoadingTable() {
      this.bLoadingTable = true;
      this.bEmptyTable = false;
      setTimeout(() => {
        this.bLoadingTable = false;
        this.bEmptyTable = this.aCards.length < 1;
      }, 1000);
    },
    openDetailItem(item) {
      this.$emit("openDetailItem", item);
    },
    openDeleteItem(item) {
      this.$emit("openDeleteItem", item);
    },
    getEmitAccountsReceivable(obj) {
      this.$emit("getEmitAccountsReceivable", obj);
    },
    setOrder(oItem) {
      this.$emit("setOrder", oItem);
    },
  },
  watch: {
    aCards() {
      this.setLoadingTable();
    },
  },
};
</script>

<style scoped>
.content-accounts-receivable-card-all {
  /* background-color: var(--primary-color-background-table) !important; */
  min-height: 200px;
  border-radius: 15px;
}

.content-total-amount-pay-all {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px;
  border-bottom: 1px solid var(--primary-color-divider);
}
.content-txt-price-total-amount-pay-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 25px 0px;
  padding: 0px 15px;
  min-width: 100%;
  max-width: 100%;
  min-height: 60px;
  background-color: var(--primary-color-background-blue-transparent);
  border-radius: 15px;
}

.txt-total-amount-pay {
  color: var(--primary-color-text-white);
  font-family: "pop-SemiBold";
  font-size: 15px;
  margin-bottom: 0px;
}
.price-total-amount-pay {
  color: var(--primary-color-text-white);
  white-space: nowrap;
  font-family: "pop-SemiBold";
  font-size: 13px;
  margin-bottom: 0px;
}

/*Plantilla media query css responsive*/

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>

<style scoped>
.content-all-first {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.content-all {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
}

.content-left-first {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-left {
  width: 50%;
  font-family: "pop-Semibold";
  font-size: 14px;
}

.text-right {
  /* white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden; */
}

.content-right-first {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
  position: relative !important;
}

.content-right {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
}

.content-right {
  overflow: auto;
}

.content-right::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
  border-radius: 10px;
}

.content-title {
  text-align: start;
}

.text-head {
  color: var(--primary-color-text) !important;
}

.content-text {
  /* text-align: end; */
}

.text-response {
  color: var(--primary-color-text) !important;
}

.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.footer-card-all-accounts-receivable {
  background-color: var(--primary-color-background-table) !important;
}
</style>
